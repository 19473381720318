<template>
  <div :class="$style.root">
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}} #{{contractId}}</h1>
    <div class="mb-4 clearfix">
      <VBtn depressed @click="$router.push({ name: 'elkp/ContractListView' })">
        <VIcon v-text="'mdi-arrow-left'" />
        Назад к списку договоров
      </VBtn>
    </div>
    <template v-if="contract">
      <VCard elevation="0" class="mb-6">
        <VCardTitle>Номер договора, дата окончания, KA</VCardTitle>
        <VCardText>
          <VRow>
            <VCol cols="12" md="6">
              <StaticField label="Номер договора" :value="contract.number" />
            </VCol>
            <VCol cols="12" md="6">
              <StaticField label="Дата окончание действия" :value="contract.date" />
            </VCol>
            <VCol cols="12" md="6">
              <StaticField label="ИНН контрагента" :value="contract.inn" />
            </VCol>
            <VCol cols="12" md="6">
              <StaticField label="Организация" :value="contract.name" />
            </VCol>
          </VRow>
        </VCardText>
      </VCard>
      <VCard elevation="0"  class="mb-6">
        <VCardTitle>Отработано часов по договору</VCardTitle>
        <VCardText>
          <WorkHoursForm
            :readonly="!hasRole(['CONTRAGENT', 'CONTRACT_HOLDER'], 'elkp')"
            :values="contract.hours"
            @submit="values => onSetHours({ values })"
          />
        </VCardText>
      </VCard>
    </template>
    <VCard elevation="0" class="mb-6">
      <VCardTitle>
        Сотрудники задействованные по договору
      </VCardTitle>
      <VCardText>
        <EmployeeList
          :items="employees.items"
          :count="employees.count"
          :page="page"
          :size="size"
          :loading="loading"
          :disabled="!hasRole(['CONTRAGENT', 'CONTRACT_HOLDER'], 'elkp')"
          :selected.sync="employeesSelected"
          @click:row="({ id }) => $router.push({ name: 'elkp/EmployeeDetailView', params: { employee: id, contract: contractId }})"
        />
        <VFooter v-if="hasRole(['CONTRAGENT', 'CONTRACT_HOLDER'], 'elkp')" app>
          <div class="py-3 grow">
            <template v-if="!employeesSelected.length">
              <VBtn color="primary" depressed :loading="loading" @click="onCreate">
                Создать нового сотрудника
              </VBtn>
              <VBtn v-if="authorId && contractId" color="secondary" class="ml-4" depressed @click="$router.push({ name: 'elkp/EmployeeLinkView', params: { author: authorId, contract: contractId  }})">
                Добавить существующего сотрудника
              </VBtn>
            </template>
            <template v-else>
              <VBtn color="primary" depressed :loading="loading" @click="onDelete">
                Отвязать выбранных сотрудников
              </VBtn>
              <VBtn color="secondary" depressed class="ml-4" @click="employeesSelected = []">
                Отмена
              </VBtn>
            </template>
          </div>
        </VFooter>
      </VCardText>
    </VCard>
  </div>
</template>

<script>
import { debounce, get, map } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import StaticField from '@/components/general/StaticField/StaticField';
import EmployeeList from '@/components/elkp/EmployeeList/EmployeeList';
import WorkHoursForm from '@/components/elkp/WorkHoursForm/WorkHoursForm';
export default {
  name: 'ContractDetailView',
  components: {
    WorkHoursForm,
    StaticField,
    EmployeeList,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object },
  },
  data() {
    return {
      employeesSelected: [],
    }
  },
  computed: {
    ...mapGetters({
      loading: 'elkp/pending',
      contract: 'elkp/contract',
      employees: 'elkp/employees',
      hasRole: 'user/hasRole',
    }),
    contractId: function() {
      return get(this.filter, 'profileId');
    },
    authorId: function() {
      return get(this.contract, 'author');
    },
  },
  methods: {
    ...mapActions({
      fetchContractDetail: 'elkp/fetchContractDetail',
      fetchEmployeeList: 'elkp/fetchEmployeeList',
      createEmployee: 'elkp/createEmployee',
      removeEmployeeFromContract: 'elkp/removeEmployeeFromContract',
      setWorkTimeToContract: 'elkp/setWorkTimeToContract',
    }),
    onUpdateContract: debounce(function() {
      const id = this.contractId;
      this.fetchContractDetail({ id });
    }, 500),
    onUpdateEmployees: debounce(function() {
      this.employeesSelected = [];
      const { page, size, filter } = this;
      this.fetchEmployeeList({ page, size, filter });
    }, 500),
    onCreate: function() {
      const id = this.contractId;
      this.createEmployee({ id }).then((result) => {
        const employee = result;
        const contract = this.contractId;
        if (employee) this.$router.push({ name: 'elkp/EmployeeDetailView', params: { employee, contract }});
      });
    },
    onDelete: function() {
      const contract = this.contractId;
      const employees = map(this.employeesSelected, 'id');
      this.removeEmployeeFromContract({ employees, contract }).then((result) => {
        if (result) this.onUpdateEmployees();
      });
    },
    onSetHours: function({ values }) {
      const id = this.contractId;
      this.setWorkTimeToContract({ id, values }).then((result) => {
        if (result) this.onUpdateContract();
      });
    }
  },
  watch: {
    page: function() {
      this.onUpdateEmployees();
    },
    size: function() {
      this.onUpdateEmployees();
    },
    filter: {
      deep: true,
      immediate: true,
      handler: function() {
        this.onUpdateContract();
        this.onUpdateEmployees();
      }
    },
  },
}
</script>

<style module lang="scss">
.root {
  :global(.v-card) {
    border: 1px solid #dee2e6;
  }
}
</style>
